import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { checkUser, createIssueWatchAlert, getWatchedIssue } from '../../data/dataApi';
import { useUserState } from './UserStateContext';
import { Modal, Button, MultiSelect } from '@mantine/core';
import { showSuccessNotification, showUnexpectedErrorNotification } from '../helpers';

const RouteHandler = ({ element, requireAuth, admin, excludeAuth, user }) => {
    const [loading, setLoading] = useState(true);
    const { issueWatchModalOpen, closeIssueWatchModal, selectedWatchIssue } = useUserState();
    const [watchIssueTypes, setWatchIssueTypes] = useState([]);

    const { setLoggedIn } = useUserState();

    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        (async () => {
            if (!excludeAuth) {
                const response = await checkUser();

                setLoggedIn(!!response.id);

                if (response.id && !requireAuth || (admin && response.role !== "ops") || (id && user && response.id !== Number(id))) {
                    navigate("/home", { replace: true });
                } else if (!response.id && requireAuth) {
                    navigate("/", { replace: true });
                }
            }

            setLoading(false);
        })();
    }, [navigate]);

    useEffect(() => {
        if(issueWatchModalOpen) {
            setWatchIssueTypes([]);

            (async () => {
                const response = await getWatchedIssue(selectedWatchIssue);
    
                if(response.success && response.data.rows.length) {
                    setWatchIssueTypes(response.data.rows[0].types);
                }
            })();
        }
    }, [issueWatchModalOpen, selectedWatchIssue]);

    const handleSaveWatchAlert = async () => {
        const response = await createIssueWatchAlert(selectedWatchIssue, watchIssueTypes);

        if (response.success) {
            showSuccessNotification("Issue watch alert saved successfully.");
            closeIssueWatchModal();
        } else if(response.error.field === "watch_type") {
            showUnexpectedErrorNotification(response.error.message);
        } else {
            showUnexpectedErrorNotification();
        }
    };

    if (loading) {
        return <></>;
    } else {
        return (
            <>
                {element}
                <Modal opened={issueWatchModalOpen} onClose={closeIssueWatchModal} title="Watch Issue">
                    <MultiSelect
                        label="Type"
                        placeholder="Select type(s)"
                        data={[{
                            value: "time_spent_overage",
                            label: "Time Spent Overage"
                        }]}
                        value={watchIssueTypes}
                        onChange={setWatchIssueTypes}
                    />

                    <Button fullWidth mt={20} onClick={handleSaveWatchAlert}>Save</Button>
                </Modal>
            </>
        );
    }
};

export default RouteHandler;