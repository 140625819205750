import {
    Text,
    Accordion,
    Tabs,
    rem,
    Skeleton
} from '@mantine/core';
import {
    IconUsers,
    IconCode,
    IconPalette,
    IconSettingsCog
} from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';
import IndividualStat from './IndividualStat';
import { useUserState } from './UserStateContext';
import { getAllUsers } from '../../data/dataApi';
import { handleUserStats } from '../helpers';

const IndividualStats = ({ excludeAccounts }) => {
    const { individualStatistics, setIndividualStatistics, setUsers, refresh, setRefresh, statsDate } = useUserState();
    const [openItem, setOpenItem] = useState();

    const userStatsFetched = useRef(false); // Prevents duplicate API calls on development mode

    const TabPanel = ({ role }) => (
        <Tabs.Panel value={role}>
            <Accordion chevronPosition="right" variant="contained" onChange={item => setOpenItem(item)} value={openItem}>
                {
                    role !== "all" ?
                        individualStatistics.sort((a, b) => a.name.localeCompare(b.name)).filter(individual => individual.role === role).map((user, index) => (
                            <IndividualStat user={user} key={index} excludeAccounts={excludeAccounts} />
                        ))
                    :
                        individualStatistics.sort((a, b) => a.name.localeCompare(b.name)).map((user, index) => (
                            <IndividualStat user={user} key={index} excludeAccounts={excludeAccounts} />
                        ))
                }
            </Accordion>
        </Tabs.Panel>
    );


    useEffect(() => {
        if(excludeAccounts !== "null" && localStorage.getItem("exclude_accounts") !== String(excludeAccounts)) {
            const timer = setTimeout(() => {
                setIndividualStatistics([]);
                userStatsFetched.current = false;
            }, 1000);

            return () => clearTimeout(timer);
        }
      }, [excludeAccounts]);

    useEffect(() => {
        if(refresh || (!individualStatistics.length && !userStatsFetched.current)) {
            userStatsFetched.current = true;
            sessionStorage.setItem('elapsedTime', '0');
            setRefresh(false);

            (async () => {
                const response = await getAllUsers();
                
                if(response.success) {
                    const users = response.data.rows;

                    setUsers(users);

                    users.forEach(async user => {
                        handleUserStats(user, setIndividualStatistics, excludeAccounts, statsDate);
                    });
                }
            })();
        }
    }, [refresh, individualStatistics, statsDate]);

    return (
        <>
            {
                individualStatistics.length ?
                <>
                    <Text size="sm" className="tif-ls-50 text-uppercase tif-mb-15">
                        Individual Stats
                    </Text>
                    <Tabs defaultValue="all">
                        <Tabs.List className="tif-mb-15">
                            <Tabs.Tab value="all" fz="md" leftSection={<IconUsers style={{ width: rem(16), height: rem(16) }} />}>
                                All
                            </Tabs.Tab>
                            <Tabs.Tab value="dev" fz="md" leftSection={<IconCode style={{ width: rem(16), height: rem(16) }} />}>
                                Dev
                            </Tabs.Tab>
                            <Tabs.Tab value="design" fz="md" leftSection={<IconPalette style={{ width: rem(16), height: rem(16) }} />}>
                                Design
                            </Tabs.Tab>
                            <Tabs.Tab value="ops" fz="md" leftSection={<IconSettingsCog style={{ width: rem(16), height: rem(16) }} />}>
                                Ops
                            </Tabs.Tab>
                        </Tabs.List>
                
                        <TabPanel role="all" />
                        <TabPanel role="dev" />
                        <TabPanel role="design" />
                        <TabPanel role="ops" />
                    </Tabs>
                </>
            :
                <>
                    <Skeleton height={25} radius="md" className="tif-mb-15" />
                    <Skeleton height={38} radius="md" className="tif-mb-15" />
                    <Skeleton height={606} radius="md" />
                </>
            }
        </>
    );
};

export default IndividualStats;