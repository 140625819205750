import { ActionIcon, Menu, Table } from "@mantine/core";
import { Avatar, Badge, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { priorityColors, extractTextFromContent } from "../helpers.js";
import { IconDots, IconEye } from "@tabler/icons-react";
import { useUserState } from "./UserStateContext.js";

const TicketOverviewTable = ({ issues, queries, tab }) => {
    const { openIssueWatchWatchModal } = useUserState();

    return (
        <Table verticalSpacing="sm" highlightOnHover>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th style={{ minWidth: "175px" }}>Assignee</Table.Th>
                    <Table.Th>Summary</Table.Th>
                    <Table.Th>Description</Table.Th>
                    <Table.Th className="text-nowrap">Time Estimate</Table.Th>
                    <Table.Th className="text-nowrap">Time Spent</Table.Th>
                    <Table.Th colSpan={2}>Updated</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {
                    issues.length ?
                        issues.map((issue, index) => (
                            <Table.Tr key={index} className="cursor-pointer" style={{ color: (issue.fields.priority?.name !== "Medium") ? priorityColors[issue.fields.priority?.name] : ""}} onClick={() => window.open(`https://thinkitfirst.atlassian.net/browse/${issue.key}`, '_blank')}>
                                <Table.Td className="d-flex align-items-center">
                                    <Avatar size={25} src={issue.fields.assignee?.avatarUrls['48x48']} className="flex-shrink-0 tif-mr-10" />
                                    <Badge color={priorityColors[issue.fields.priority?.name]} className={`position-relative flex-shrink-0 ${(issue.fields.priority?.name === "Highest") ? "pulse-animation" : ""}`} variant="light">
                                        {issue.key}
                                    </Badge>
                                </Table.Td>
                                <Table.Td className="truncate">
                                    <Text fz="sm" fw={700} style={{ minWidth: 0 }} truncate="end" className="position-relative">
                                        {issue.fields.summary}
                                    </Text>
                                </Table.Td>
                                <Table.Td className="truncate" style={{ minWidth: 350 }}>
                                    <Text fz="sm" style={{ minWidth: 0 }} truncate="end" className="position-relative">
                                        {issue.fields.description ? extractTextFromContent(issue.fields.description.content) : ""}
                                    </Text>
                                </Table.Td>
                                <Table.Td>
                                    <Text fz="sm">{(issue.fields.timeoriginalestimate / 3600).toFixed(1)}h</Text>
                                </Table.Td>
                                <Table.Td>
                                    <Text fz="sm">{(issue.fields.aggregatetimespent / 3600).toFixed(1)}h</Text>
                                </Table.Td>
                                <Table.Td className="text-nowrap">
                                    <Text fz="sm">{new Date(issue.fields.updated).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</Text>
                                </Table.Td>
                                <Table.Td onClick={(e) => e.stopPropagation()}>
                                    <Menu
                                        transitionProps={{ transition: 'pop' }}
                                        withArrow
                                        position="bottom-end"
                                        withinPortal
                                    >
                                        <Menu.Target>
                                            <ActionIcon variant="default" aria-label="Options" size="sm">
                                                <IconDots style={{ width: '14px', height: '14px' }} stroke={1.5} />
                                            </ActionIcon>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            <Menu.Item
                                                leftSection={
                                                    <IconEye style={{ width: '16px', height: '16px' }} stroke={1.5} />
                                                }
                                                onClick={() => openIssueWatchWatchModal(issue.key)}
                                            >
                                                Watch Issue
                                            </Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                </Table.Td>
                            </Table.Tr>
                        ))
                    :
                        <Text mt="10px">0 tickets.</Text>
                }
            </Table.Tbody>
            <Table.Caption className="text-center">
                <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=${queries[tab]}`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">View in Jira</Link>
            </Table.Caption>
        </Table>
    );
};

export default TicketOverviewTable;