import { useState } from 'react';
import { Button, Group, Text, TextInput} from '@mantine/core';
import { saveFieldGroup } from '../../data/dataApi';
import { showSuccessNotification, showUnexpectedErrorNotification } from '../helpers';
import FieldGroup from './FieldGroup';

const CreateFieldGroupSettings = ({ client, setTabs }) => {
    const [name, setName] = useState();
    const [nameError, setNameError] = useState();
    const [fields, setFields] = useState([]);
    const [fieldsError, setFieldsError] = useState();

    const handleSave = async () => {
        setNameError("");
        setFieldsError("");

        if(!name) {
            setNameError("Name is required.");
        } else if (!fields.length || !fields.every(field => (field.name && field.value))) {
            setFieldsError("All fields must have a name and value.");
        } else {
            const response = await saveFieldGroup(client.id, name, JSON.stringify(fields));

            if (response.success) {
                setName("");
                setFields([]);
                showSuccessNotification("Client settings saved successfully.");
                setTabs(prevState => ([...prevState, { id: response.fieldGroupId, label: name, link: `#field-group-${response.fieldGroupId}` }]));
            } else {
                showUnexpectedErrorNotification();
            }
        }
    };

    return (
        <div className="d-flex flex-column flex-grow-1">
            <TextInput
                label="Name"
                placeholder="i.e. Client Contacts"
                value={name}
                onChange={e => setName(e.target.value)}
                error={nameError}
                className="tif-mb-20"
            />

            <p>Fields</p>

            {
                fields.map((field, index) => (
                    <FieldGroup field={field} onChange={setFields} position={index} key={index} />
                ))
            }

            <Button variant="light" onClick={() => setFields(prevState => ([...prevState, { newField: true }]))} mb="30">Add Field</Button>

            <Group justify="right" mt="auto">
                { fieldsError && <Text color="red" fz="14px">{fieldsError}</Text> }
                <Button onClick={handleSave} variant="outline">
                    Save
                </Button>
            </Group>
        </div>
    );
};

export default CreateFieldGroupSettings;