import {
    Group,
    Avatar,
    Text,
    Accordion,
    Badge,
    useComputedColorScheme
} from '@mantine/core';
import {
    IconClock,
    IconCurrentLocation,
    IconAlertTriangle,
    IconClockQuestion,
    IconClockOff,
} from '@tabler/icons-react';
import { filteredOutsideMonth, filteredOutsideMonthHours } from '../helpers';
import {
    getNetworkDays,
    firstMonthDay,
    today,
    getLatestDate,
    dateWithinCurrentMonth,
    getPastMonthHoursAvailable
} from '../helpers';
import { useState, useEffect } from 'react';
import IndividualStatContent from './IndividualStatContent';
import { formatUserPTOMessage } from '../helpers';
import { useUserState } from './UserStateContext';

const IndividualStat = ({ user, excludeAccounts }) => {
    let hoursAvailable = (getNetworkDays() * user.daily_billable_goal) - (user.pto ? filteredOutsideMonthHours(user.pto) : 0);
    const { statsDate } = useUserState();

    if(!dateWithinCurrentMonth(statsDate)) {        
        hoursAvailable = getPastMonthHoursAvailable(user, statsDate);
    }

    const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });

    const forthcomingUserTimeoff = () => {
        const timeOffDates = filteredOutsideMonth(user.pto || []);

        const latestTimeOffDate = getLatestDate(timeOffDates);

        const latestDate = new Date(latestTimeOffDate);
        const todayDate = new Date(today);
        todayDate.setHours(0, 0, 0, 0);

        if (latestDate >= todayDate) {
            return timeOffDates;
        } else {
            return [];
        }
    };

    const [currentMonthUserTimeOff, setCurrentMonthUserTimeOff] = useState(forthcomingUserTimeoff());

    const tracking = !dateWithinCurrentMonth(statsDate) ? Math.round((user.time.billed / hoursAvailable) * 100) : Math.round((((user.time.billed) * (getNetworkDays() / getNetworkDays(firstMonthDay, today))) / hoursAvailable) * 100);

    const getTrackingStatusColor = () => {
        if(tracking >= 80) {
            return "gray";
        } else if(tracking >= 60 && tracking < 80) {
            return "orange";
        } else {
            return "red";
        }
    };

    const AccordionLabel = ({ name, avatar_url }) => (
        <Group wrap="nowrap">
            <Avatar src={avatar_url} radius="xl" size="30" />
            <div>
                <Text>{name}</Text>
            </div>
            <div>
                <IconClock color={ user.time.previousLoggedDays <= 1 ? "green" : "red" } className="icon align-text-bottom" size="1.125rem" stroke={1.5} />
            </div>
            <Badge color={ getTrackingStatusColor() } variant="light" leftSection={<IconCurrentLocation className="icon" color={ getTrackingStatusColor() } size="0.75rem" stroke={1.5} />}>{ `${ tracking }%` }</Badge>
            <Badge color="gray" variant="light" leftSection={<IconClockQuestion className="icon" color={computedColorScheme === "dark" ? "white" : "black"} size="0.75rem" stroke={1.5} />}>{ user.ticketCounts.open } open tickets</Badge>
            
            { 
                user.ticketCounts.atRisk > 0 ?
                    <Badge color="red" variant="filled" leftSection={<IconAlertTriangle className="icon" color="white" size="0.75rem" stroke={1.5} />}>{ user.ticketCounts.atRisk } at risk tickets</Badge>
                :
                    <></>
            }

            {
                currentMonthUserTimeOff?.length ?
                    <Badge color="blue" className="user-month-time-off" variant="light" leftSection={<IconClockOff className="icon" color="lightblue" size="0.75rem" stroke={1.5} />}>{ formatUserPTOMessage({ pto: currentMonthUserTimeOff }, true) }</Badge>
                :
                    <></>
            }
        </Group>
    );

    useEffect(() => {
        if (user) {
            setCurrentMonthUserTimeOff(forthcomingUserTimeoff());
        }
    }, []);

    return (
        <Accordion.Item value={user.id}>
            <Accordion.Control className="user-accordion-control position-sticky">
                {
                    user ?
                        <AccordionLabel {...user} />
                    :
                        <></>
                }
            </Accordion.Control>
            <Accordion.Panel className="tif-p-15">
                {
                    user ?
                        <IndividualStatContent user={user} excludeAccounts={excludeAccounts} />
                    :
                        <></>
                }
            </Accordion.Panel>
        </Accordion.Item>
    )
};

export default IndividualStat;