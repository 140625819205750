import {
    Group,
    Avatar,
    Badge,
    Tooltip,
    Text,
    Menu
} from '@mantine/core';
import { Link } from 'react-router-dom';
import { IconBellPlus, IconExternalLink } from '@tabler/icons-react';
import { priorityColors } from '../helpers';
import { useUserState } from './UserStateContext';

const TicketList = ({ list, isAccount, disableAvatar, noLimit }) => {
    const { openIssueWatchWatchModal } = useUserState();

    if (list?.length) {
        if (isAccount) {
            list = list.filter(item => item.avatarUrl && item.name);
        }

        list = noLimit ? list : list.slice(0, 10);

        return list.map((item, index) => (
            <Group gap="10px" className="flex-nowrap tif-mb-10 w-100" key={index} style={{ cursor: "pointer" }}>
                {
                    !disableAvatar ?
                        <Avatar size={24} src={!isAccount ? item.fields.assignee?.avatarUrls['48x48'] : item.avatarUrl} radius={30} />
                        :
                        <></>
                }
                {
                    item.fields?.summary ?
                            <Menu withArrow>
                                <Menu.Target>
                                    <Group gap="10px" className="truncate-wrapper flex-nowrap">
                                        <Badge color={!isAccount ? priorityColors[item.fields.priority?.name] || "gray" : "gray"} className={`position-relative flex-shrink-0 ${(!isAccount && item.fields.priority?.name === "Highest") ? "pulse-animation" : ""}`} variant="light">
                                            {!isAccount ? item.key : item.name}
                                        </Badge>
                                        <Tooltip.Floating label={item.fields.summary}>
                                            <div className="truncate-wrapper">
                                                <Text fz="sm" style={{ minWidth: 0 }} truncate="end" color={(!isAccount && item.fields.priority?.name !== "Medium") ? priorityColors[item.fields.priority?.name] : ""} className="position-relative">
                                                    {item.fields.summary}
                                                </Text>
                                            </div>
                                        </Tooltip.Floating>
                                    </Group>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <Menu.Item
                                        leftSection={<IconExternalLink style={{ width: 14, height: 14 }} />}
                                        component="a"
                                        href={`https://thinkitfirst.atlassian.net/browse/${item.key}`}
                                        target="_blank"
                                    >
                                        Open issue
                                    </Menu.Item>
                                    <Menu.Item
                                        leftSection={<IconBellPlus style={{ width: 14, height: 14 }} />}
                                        onClick={() => openIssueWatchWatchModal(item.key)}
                                    >
                                        Watch issue
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        :
                            <Badge color={!isAccount ? priorityColors[item.fields.priority?.name] || "gray" : "gray"} className="position-relative" variant="light">
                                {!isAccount ? item.key : item.name}
                                {!isAccount ? <Link to={`https://thinkitfirst.atlassian.net/browse/${item.key}`} className="stretched-link" target="_blank"></Link> : <></>}
                            </Badge>
                }
            </Group>
        ));
    } else {
        return <p>0 {!isAccount ? "tickets" : "accounts"}.</p>
    }
};

export default TicketList;