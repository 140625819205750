import { useState } from 'react';
import {
    Text,
    SimpleGrid,
    Group,
    Tabs,
    Badge,
} from '@mantine/core';
import {
    IconClockQuestion,
    IconUsers,
    IconClockPlay,
    IconClockSearch,
    IconClockX,
    IconFlame,
    IconClockExclamation,
    IconEyeCode,
} from '@tabler/icons-react';
import Card from './Card';
import { Link } from 'react-router-dom';
import {
    getNetworkDays,
    firstMonthDay,
    today,
    filteredOutsideMonthHours,
    dateWithinCurrentMonth,
    getPastMonthHoursAvailable
} from '../helpers';
import { getExcludedAccountsString } from '../../data/dataApi';
import ViewModeControl from './ViewModeControl';
import TicketOverviewTable from './TicketOverviewTable';
import TicketList from './TicketList';
import { useUserState } from './UserStateContext';

const IndividualStatContent = ({ user, excludeAccounts }) => {
    const [viewMode, setViewMode] = useState(localStorage.getItem('individual-statistics_view_mode') || 'grid-view');
    const [listViewTab, setListViewTab] = useState('emptyTimeEstimateTickets');
    const { statsDate } = useUserState();
    let hoursAvailable = (getNetworkDays() * user.daily_billable_goal) - (user.pto ? filteredOutsideMonthHours(user.pto) : 0);
    
    if(!dateWithinCurrentMonth(statsDate)) {        
        hoursAvailable = getPastMonthHoursAvailable(user, statsDate);
    }

    const ticketsOverviewQueries = {
        openTickets: `assignee%20%3D%20${user.jira_account_id}%20AND%20statuscategory%20%3D%20New%20AND%20status%20%21%3D%20"On%20Hold"${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`,
        inProgressTickets: `assignee%20%3D%20${user.jira_account_id}%20AND%20status%20%3D%20"In%20Progress"${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`,
        codeReviewTickets: `assignee%20%3D%20${user.jira_account_id}%20AND%20status%20%3D%20"Code%20Review"${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`,
        internalReviewTickets: `status%20%3D%20"Internal%20Review"%20AND%20assignee%20%3D%20${user.jira_account_id}${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`,
        customerReviewTickets: `status%20%3D%20"Client%20Review"%20AND%20assignee%20%3D%20${user.jira_account_id}${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`,
        highPriorityTickets: `priority%20>%20Medium%20AND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20NOT%20IN%20%28Done%2C%20Deployed%2C%20Cancelled%29${getExcludedAccountsString(excludeAccounts)}%20AND%20assignee%20%3D%20${user.jira_account_id}%20ORDER%20BY%20created%20DESC`,
        whatsHotTickets: `workratio%20>%20100%20AND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20NOT%20IN%20%28Done%2C%20Deployed%2C%20Cancelled%29${getExcludedAccountsString(excludeAccounts)}%20AND%20assignee%20%3D%20${user.jira_account_id}%20ORDER%20BY%20workratio%20DESC`,
        emptyTimeEstimateTickets: `originalestimate%20IS%20empty%0AAND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%0AAND%20status%20NOT%20IN%20%28Done%2C%20Deployed%2C%20Cancelled%2C%20"On%20Hold"%2C%20"Code%20Review"%2C%20"Internal%20Review"%2C%20"Cancelled"%29%0AAND%20assignee%20%3D%20${user.jira_account_id}${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`,
        whatsHot: `workratio%20>%20100%20AND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20NOT%20IN%20%28Done%2C%20Deployed%2C%20Cancelled%29${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20workratio%20DESC`,
        newTickets: `issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`,
        ticketLimbo: `updated%20<%3D%20-12w%20AND%20status%20NOT%20IN%20%28Cancelled%2C%20Deployed%2C%20Done%29%20AND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20priority%20DESC%2C%20created%20DESC`,
        unassigned: `status%20NOT%20IN%20%28Done%2C%20Deployed%2C%20Cancelled%29%20AND%20issuetype%20NOT%20IN%20%28"TIF%20Team%20Task"%2C%20Epic%29%20AND%20assignee%20IS%20EMPTY%20AND%20status%20%21%3D%20"On%20Hold"${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20priority%20DESC%2C%20created%20DESC`,
        onHold: `issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20%3D%20"On%20Hold"${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20updated%20DESC`,
        customerReview: `issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20%3D%20"Client%20Review"${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20updated%20DESC`,
    };

    return (
        <>
            <Text size="lg" className="tif-mb-10 fw-bold">
                Time <span className="tif-font-xs fw-normal">(by month)</span>
            </Text>
            <div className="stat-panel tif-mb-30">
                <div className="stat-container">
                    <Text className="stat-count">{hoursAvailable}</Text>
                    <Text className="stat-title">Billable Target</Text>
                </div>
                <div className="stat-container">
                    <Text className="stat-count">{user.time.billed}</Text>
                    <Text className="stat-title">Billed</Text>
                </div>
                <div className="stat-container">
                    <Text className="stat-count">{`${!dateWithinCurrentMonth(statsDate) ? Math.round((user.time.billed / hoursAvailable) * 100) : Math.round(((user.time.billed * (getNetworkDays() / getNetworkDays(firstMonthDay, today))) / hoursAvailable) * 100)}%`}</Text>
                    <Text className="stat-title">Tracking</Text>
                </div>
                <div className="stat-container">
                    <Text className="stat-count">{user.time.previousLoggedDays}</Text>
                    <Text className="stat-title">Days Since Logged</Text>
                </div>
                <div className="stat-container">
                    <Text className="stat-count">{user.time.yesterdayBillable}</Text>
                    <Text className="stat-title">Yesterday's Billable</Text>
                </div>
                <div className="stat-container">
                    <Text className="stat-count">{user.time.yesterdayUnbillable}</Text>
                    <Text className="stat-title">Yesterday's Unbillable</Text>
                </div>
            </div>
            <Group mb="10px" align="center" justify="space-between">
                <Text size="lg" className="tif-mb-10 fw-bold">
                    Ticket Overview
                </Text>
                <ViewModeControl viewMode={viewMode} onChange={setViewMode} id="individual-statistics" />
            </Group>
            {
                viewMode === 'grid-view' ?
                    <SimpleGrid cols={{ base: 1, md: 4 }} className="tif-mb-40">
                        <Card title="Empty Time Estimate" Icon={IconClockX} count={user.emptyTimeEstimateTickets.total}>
                            <TicketList list={user.emptyTimeEstimateTickets.issues} />
                            {user.emptyTimeEstimateTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=originalestimate%20IS%20empty%0AAND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%0AAND%20status%20NOT%20IN%20%28Done%2C%20Deployed%2C%20Cancelled%2C%20"On%20Hold"%2C%20"Code%20Review"%2C%20"Internal%20Review"%2C%20"Cancelled"%29%0AAND%20assignee%20%3D%20${user.jira_account_id}${getExcludedAccountsString(excludeAccounts)}%0AORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">View in Jira</Link> : <></>}
                        </Card>
                        <Card title="Open Tickets" Icon={IconClockQuestion} count={user.openTickets.total}>
                            <TicketList list={user.openTickets.issues} />
                            {user.openTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=assignee%20%3D%20${user.jira_account_id}%20AND%20statuscategory%20%3D%20New%20AND%20status%20%21%3D%20"On%20Hold"${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">View in Jira</Link> : <></>}
                        </Card>
                        <Card title="In Progress Tickets" Icon={IconClockPlay} count={user.inProgressTickets.total}>
                            <TicketList list={user.inProgressTickets.issues} />
                            {user.inProgressTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=assignee%20%3D%20${user.jira_account_id}%20AND%20status%20%3D%20"In%20Progress"${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">View in Jira</Link> : <></>}
                        </Card>
                        <Card title="Code Review Tickets" Icon={IconEyeCode} count={user.codeReviewTickets.total}>
                            <TicketList list={user.codeReviewTickets.issues} />
                            {user.codeReviewTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=assignee%20%3D%20${user.jira_account_id}%20AND%20status%20%3D%20"Code%20Review"${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">View in Jira</Link> : <></>}
                        </Card>
                        <Card title="Internal Review Tickets" Icon={IconClockSearch}  count={user.internalReviewTickets.total}>
                            <TicketList list={user.internalReviewTickets.issues} />
                            {user.internalReviewTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=status%20%3D%20"Internal%20Review"%20AND%20assignee%20%3D%20${user.jira_account_id}${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">View in Jira</Link> : <></>}
                        </Card>
                        <Card title="Customer Review Tickets" Icon={IconClockSearch}  count={user.customerReviewTickets.total}>
                            <TicketList list={user.customerReviewTickets.issues} />
                            {user.customerReviewTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=status%20%3D%20"Client%20Review"%20AND%20assignee%20%3D%20${user.jira_account_id}${getExcludedAccountsString(excludeAccounts)}%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">View in Jira</Link> : <></>}
                        </Card>
                        <Card title="High Priority" Icon={IconClockExclamation} count={user.highPriorityTickets.total}>
                            <TicketList list={user.highPriorityTickets.issues} />
                            {user.highPriorityTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=priority%20>%20Medium%20AND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20NOT%20IN%20%28Done%2C%20Deployed%2C%20Cancelled%29${getExcludedAccountsString(excludeAccounts)}%20AND%20assignee%20%3D%20${ user.jira_account_id }%20ORDER%20BY%20created%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">View in Jira</Link> : <></>}
                        </Card>
                        <Card title="What's Hot" Icon={IconFlame} count={user.whatsHotTickets.total}>
                            <TicketList list={user.whatsHotTickets.issues} />
                            {user.whatsHotTickets.issues?.length ? <Link to={`https://thinkitfirst.atlassian.net/issues/?jql=workratio%20>%20100%20AND%20issuetype%20NOT%20IN%20%28Epic%2C%20"TIF%20Team%20Task"%29%20AND%20status%20NOT%20IN%20%28Done%2C%20Deployed%2C%20Cancelled%29${getExcludedAccountsString(excludeAccounts)}%20AND%20assignee%20%3D%20${user.jira_account_id}%20ORDER%20BY%20workratio%20DESC`} className="btn-anchor tif-mt-auto tif-mx-auto" target="_blank">View in Jira</Link> : <></>}
                        </Card>
                        <Card title="Top Clients" Icon={IconUsers}>
                            <TicketList list={user.recentClients} isAccount />
                        </Card>
                    </SimpleGrid>
                :
                    <Group className="tif-mb-40" wrap="nowrap" align="flex-start">
                        <Card title="Top Clients" Icon={IconUsers} className="w-25">
                            <TicketList list={user.recentClients} isAccount />
                        </Card>
                        <Tabs value={listViewTab} onChange={setListViewTab} mb="40px">
                            <Tabs.List>
                                <Tabs.Tab value="emptyTimeEstimateTickets" leftSection={<Badge variant="light" leftSection={<IconClockX size="12px" />}>{user.emptyTimeEstimateTickets.total}</Badge>}>
                                    Empty Time Estimate
                                </Tabs.Tab>
                                <Tabs.Tab value="openTickets" leftSection={<Badge variant="light" leftSection={<IconClockQuestion size="12px" />}>{user.openTickets.total}</Badge>}>
                                    Open
                                </Tabs.Tab>
                                <Tabs.Tab value="inProgressTickets" leftSection={<Badge variant="light" leftSection={<IconClockPlay size="12px" />}>{user.inProgressTickets.total}</Badge>}>
                                    In Progress
                                </Tabs.Tab>
                                <Tabs.Tab value="codeReviewTickets" leftSection={<Badge variant="light" leftSection={<IconEyeCode size="12px" />}>{user.codeReviewTickets.total}</Badge>}>
                                    Code Review
                                </Tabs.Tab>
                                <Tabs.Tab value="internalReviewTickets" leftSection={<Badge variant="light" leftSection={<IconClockSearch size="12px" />}>{user.internalReviewTickets.total}</Badge>}>
                                    Internal Review
                                </Tabs.Tab>
                                <Tabs.Tab value="customerReviewTickets" leftSection={<Badge variant="light" leftSection={<IconClockSearch size="12px" />}>{user.customerReviewTickets.total}</Badge>}>
                                    Customer Review
                                </Tabs.Tab>
                                <Tabs.Tab value="highPriorityTickets" leftSection={<Badge variant="light" leftSection={<IconClockExclamation size="12px" />}>{user.highPriorityTickets.total}</Badge>}>
                                    High Priority
                                </Tabs.Tab>
                                <Tabs.Tab value="whatsHotTickets" leftSection={<Badge variant="light" leftSection={<IconFlame size="12px" />}>{user.whatsHotTickets.total}</Badge>}>
                                    What's Hot
                                </Tabs.Tab>
                            </Tabs.List>

                            <Tabs.Panel value={listViewTab}>
                                <TicketOverviewTable issues={user[listViewTab].issues} queries={ticketsOverviewQueries} tab={listViewTab} />
                            </Tabs.Panel>
                        </Tabs>
                    </Group>
            }
        </>
    )
};

export default IndividualStatContent;